<template>
  <div class="graduteInfo">
    <h2 class="is-center">{{ $route.query.type ? "录取信息" : "毕业信息" }}</h2>
    <van-form @submit="doQuery" v-if="!showInfo">
      <van-cell-group inset>
        <template v-if="$route.query.type">
          <van-field
            v-model="form.studentName"
            name="姓名"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="form.cardId"
            name="身份证号"
            label="身份证号"
            placeholder="请输入身份证号"
            :rules="[{ required: true }]"
          />
        </template>
        <van-field
          v-else
          v-model="form.studentNum"
          name="学号"
          label="学号"
          placeholder="请输入学号"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="form.code"
          clearable
          name="验证码"
          label="验证码"
          placeholder="请输入验证码"
          :rules="[{ required: true }]"
        >
          <template #button>
            <!-- <van-button size="small" type="primary">发送验证码</van-button> -->
            <van-image
              width="2.5rem"
              height="1rem"
              fit="fill"
              :src="codeUrl"
              @click="getCodeUrl"
            />
          </template>
        </van-field>
      </van-cell-group>
      <div style="margin: 16px">
        <van-button
          round
          block
          :color="themeColor"
          type="primary"
          native-type="submit"
        >
          查询
        </van-button>
      </div>
    </van-form>
    <div class="eduInfo" v-else>
      <template v-if="$route.query.type">
        <div class="eduInfoMain">
          <van-cell-group inset>
            <van-cell title="录取结果" :value="info.studentStatusStr" />
            <van-cell title="姓名" :value="info.name" />
            <van-cell title="批次" :value="info.admissionBatch || '暂无'" />
            <van-cell title="身份证号" :value="info.cardId || '暂无'" />
            <van-cell title="专业" :value="info.majorName || '暂无'" />
            <van-cell title="考生号" :value="info.examNum || '暂无'" />
            <van-cell title="层次" :value="info.eduCategoryName || '暂无'" />
            <van-cell title="学制" :value="info.learningSystem || '暂无'" />
            <van-cell title="教学点" :value="info.stationsName || '暂无'" />
            <van-cell title="教学点联系方式" :value="info.stationsPhone || '暂无'" />
          </van-cell-group>
          <div class='tips'>如有疑问，请咨询学校。<span v-if="info&&info.schoolPhone">联系电话：{{info.schoolPhone}}</span></div>
          <van-button
            round
            block
            :color="themeColor"
            class="eduInfoBack"
            type="primary"
            @click="closeEvent()"
          >
            返回
          </van-button>
        </div></template>
      <template v-else>
        <div class="eduInfoHead">
          <div class="infoImgbox">
            <img
              class="infoImg"
              v-if="info.graduationPhotoUrl"
              :src="info.graduationPhotoUrl"
              alt=""
              @click.prevent="getCodeUrl"
            />
            <div class="infoImgNo" v-else>暂无毕业照片</div>
          </div>
          <span>毕业照片</span>
        </div>
        <div class="eduInfoMain">
          <van-cell-group inset>
            <van-cell title="姓名" :value="info.name" />
            <van-cell title="性别" :value="info.sex || '暂无'" />
            <van-cell
              title="学制"
              :value="
                info.learningSystem >= 4 ? info.learningSystem + '年' : '1-3年'
              "
            />
            <van-cell title="学校名称" :value="info.tenantName || '暂无'" />
            <van-cell title="批次" :value="info.admissionBatch || '暂无'" />
            <van-cell title="层次" :value="info.eduCategoryName || '暂无'" />
            <van-cell title="学号" :value="info.studentNum || '暂无'" />
            <van-cell title="专业" :value="info.majorName || '暂无'" />
            <van-cell
              title="入学日期"
              :value="timestampReturnDate(info.admissionTime) || '暂无'"
            />
            <van-cell
              title="毕业审核"
              :value="info.graduateAuditStatus || '暂无'"
            />
            <van-cell title="毕业状态" :value="info.graduateStatus || '暂无'" />
            <van-cell
              title="毕业时间"
              :value="timestampReturnDate(info.graduateTime) || '暂无'"
            />
            <van-cell title="毕业证号" :value="info.graduateNum || '暂无'" />
            <van-cell title="身份证号" :value="info.cardId || '暂无'" />
            <!-- <van-cell title="学位审核" :value="info.degreeAuditStatus || '暂无'" />
                <van-cell title="学位状态" :value="info.degreeStatus || '暂无'" />
                <van-cell title="获得学位时间" :value="timestampReturnDate(info.degreeTime) || '暂无'" />
                <van-cell title="学位证号" :value="info.degreeNum || '暂无'" /> -->
          </van-cell-group>
          <van-button
            round
            block
            :color="themeColor"
            class="eduInfoBack"
            type="primary"
            @click="closeEvent()"
          >
            返回
          </van-button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { Toast, showSuccessToast, showFailToast } from "vant";
import way from "@/api/encapsulation";
export default {
  name: "graduteInfo",
  components: {},
  data() {
    return {
      info: {},
      showInfo: false,
      isPhoneDisabled: false,
      form: {
        // code: null,
        // cardId: "110101199003070337",
        // studentName: "蔡志培4567",
        code: null,
        studentNum: null,
      },
      themeColor: "#1a7ee2",
      codeId: null,
      codeUrl: "",
      // codeUrl: 'https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg'
    };
  },
  created() {
    let list = {
      0: "#1a7ee2",
      1: "#A13838",
      2: "#009B4c",
    };
    if (sessionStorage.getItem("schoolInfo")) {
      let info = JSON.parse(sessionStorage.getItem("schoolInfo"));
      this.themeColor = list[parseInt(info.homeColor)];
    }
    this.getCodeUrl();
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    changeImg() {
      this.codeUrl = "";
    },
    closeEvent() {
      this.info = {};
      this.form = {};
      this.showInfo = false;
    },
    // 获取验证码
    getCodeUrl() {
      console.log(this.codeId);
      let getApi=()=>{
        if (this.$route.query.type) {
          return this.$request.getAdmCode({ id: this.codeId })
        } else {
          return this.$request.getEduCode({ id: this.codeId })
        }
      }
      getApi().then((res) => {
        if (res.data.code == 0) {
          this.codeId = res.data.data.id;
          this.codeUrl = "data:image/jpeg;base64," + res.data.data.img;
        }
      })
      .catch((err) => {});
    },
    doQuery(formName) {
      if (this.$route.query.type) {
        this.queryEvent2(formName)
      } else {
        this.queryEvent(formName)
      }
    },
    // 查询毕业信息
    queryEvent(formName) {
      console.log(formName);
      let obj = {
        id: this.codeId,
        code: this.form.code,
        tenantId: sessionStorage.getItem("tenant_id"),
        studentNum: this.form.studentNum,
      };
      console.log(obj, "obj");
      if (obj.id && obj.code && obj.studentNum) {
        this.$request
          .getEduQuery(obj)
          .then((res) => {
            if (res.data.code == 0) {
              this.showInfo = !this.showInfo;
              this.info = res.data.data;
            } else {
              this.getCodeUrl();
            }
          })
          .catch((err) => {
            this.getCodeUrl();
            return Toast(err.response.data.msg);
          });
      } else if (!obj.code) {
        return Toast("验证码未填");
      } else if (!obj.studentNum) {
        return Toast("学号未填");
      } else {
        return Toast("请重试");
      }
    },
    // 查询录取信息
    queryEvent2(formName) {
      console.log(formName);
      let obj = {
        id: this.codeId,
        code: this.form.code,
        cardId: this.form.cardId,
        tenantId: sessionStorage.getItem("tenant_id"),
        studentName: this.form.studentName,
      };
      console.log(obj, "obj");
      if (obj.id && obj.code && obj.studentName && obj.cardId) {
        this.$request
          .getAdmQuery(obj)
          .then((res) => {
            if (res.data.code == 0) {
              this.showInfo = !this.showInfo;
              this.info = res.data.data;
            } else {
              this.getCodeUrl();
            }
          })
          .catch((err) => {
            this.getCodeUrl();
            return Toast(err.response.data.msg);
          });
      } else if (!obj.code) {
        return Toast("验证码未填");
      } else if (!obj.studentName) {
        return Toast("姓名未填");
      } else if (!obj.cardId) {
        return Toast("身份证未填");
      } else if (obj.cardId.length !== 18 && obj.cardId.length !== 15) {
        return Toast("身份证格式不正确");
      } else {
        return Toast("请重试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.eduInfo {
  .eduInfoHead {
    width: 50%;
    text-align: center;
    font-size: 14px;
    margin: 0 auto;
    .infoImgbox {
      text-align: center;
      border: 1px solid #999999;
      border-radius: 5px;
      // padding: 30px;
      width: 120px;
      height: 160px;
      margin: 0 auto;
      overflow: hidden;
      .infoImgNo {
        width: 40px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .eduInfoMain {
    margin: 1rem 0;
    .tips {
      text-align: center;
    padding-top: 10px;
    font-size: 12px;
    color: #606266;
    }
    .eduInfoBack {
      width: 80%;
      margin: 0 auto;
      margin-top: 0.5rem;
    }
  }
}
.graduteInfo {
  .is-center {
    text-align: center;
    margin-bottom: 20px;
  }
  min-height: 70vh;
  padding: 100px 0 0 0;
}
</style>
